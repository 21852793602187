import { useCoin } from './use-coins';
import { Decimal } from 'turbos-clmm-sdk';
import { SwapPriceStatus } from '@constants/swap-price-status';
import { useMemo } from 'react';
import { usePrice } from './use-price';

/**
 * - false     : Never show status
 * - undefined : Route doesn't exist or data is fetching
 */
export const useSwapPriceStatus = (
  coinTypeA: string | undefined,
  coinTypeB: string | undefined,
  amountIn: string | undefined,
  amountOut: string | undefined,
) => {
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  const coinAPrice = usePrice(coinTypeA || '');
  const coinBPrice = usePrice(coinTypeB || '');

  return useMemo(() => {
    if (!coinA || !coinB || !amountIn || !amountOut || !coinBPrice?.price || !coinAPrice?.price)
      return;

    const current_price = new Decimal(amountOut)
      .mul(10 ** coinA.decimals)
      .div(new Decimal(amountIn).mul(10 ** coinB.decimals));

    const price = new Decimal(coinAPrice.price).div(coinBPrice?.price);
    const diff = new Decimal(current_price).minus(price);

    if (diff.greaterThan(0)) {
      return SwapPriceStatus.great;
    }

    const priceRatio = diff.dividedBy(price);
    if (priceRatio.greaterThan(-0.02) && priceRatio.lessThan(0)) {
      return SwapPriceStatus.fair;
    }

    if (priceRatio.lessThan(0.02) && priceRatio.greaterThan(0)) {
      return SwapPriceStatus.fair;
    }

    return SwapPriceStatus.warn;
  }, [coinA, coinB, amountIn, amountOut, coinAPrice?.price, coinBPrice?.price]);
};
