import { type ReactElement, useCallback } from 'react';
import { useRefreshFlag } from './use-refresh';
import { TransactionStatus } from '@constants/transaction-status';
import { transactionErrorMap } from '@constants/transaction-error-map';
import * as Sentry from '@sentry/react';
import { Transaction } from '@mysten/sui/transactions';
import { transactionModel } from '@models/transaction.model';
import {
  useCurrentAccount,
  useCurrentWallet,
  useSignAndExecuteTransaction,
  useSignTransaction,
  useSuiClient,
} from '@mysten/dapp-kit';
import { STASHED_WALLET_NAME, ZkSendLinkBuilder } from '@mysten/zksend';

export const useTransaction = () => {
  const client = useSuiClient();
  const { mutateAsync: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const { mutateAsync: signTransaction } = useSignTransaction();
  const currentAccount = useCurrentAccount();
  const { currentWallet } = useCurrentWallet();

  const { notifyRefresh } = useRefreshFlag();

  const transact = useCallback(
    async (
      description: string | ReactElement,
      fn: () => Promise<Transaction>,
      success?: {
        title?: string;
        description?: string | ReactElement;
        successTip?: ReactElement;
        errorTip?: ReactElement;
      },
      callback?: (options: { digest: string }) => Promise<void>,
    ): Promise<boolean> => {
      transactionModel.toggleVisible();
      transactionModel.update({
        status: TransactionStatus.confirm,
        digest: undefined,
        description,
      });
      try {
        const txb = await fn();
        let _digest;
        if (currentWallet?.name === STASHED_WALLET_NAME && currentAccount) {
          const link = new ZkSendLinkBuilder({
            sender: currentAccount.address,
            client,
          });
          link.createSendTransaction({
            transaction: txb,
          });
        }
        const { signature, bytes } = await signTransaction({ transaction: txb });
        const now = Date.now();
        const { digest } = await client.executeTransactionBlock({
          signature: signature,
          transactionBlock: bytes,
          requestType: 'WaitForEffectsCert',
        });
        const end = Date.now() - now;
        _digest = digest;
        const response = await client.waitForTransaction({
          digest: _digest,
          options: {
            showEffects: true,
          },
        });
        const effects = response.effects;
        if (effects?.status.status === 'success') {
          if (callback) callback({ digest: _digest });
          transactionModel.update({
            digest: _digest,
            status: TransactionStatus.success,
            description: success?.description || description,
            title: success?.title,
            successTip: success?.successTip,
            errorTip: success?.errorTip,
            finishedTime: end / 1000,
          });
          notifyRefresh(300);
          return true;
        } else {
          throw new Error(effects?.status.error || '');
        }
      } catch (e) {
        const errorMsg = transformMessage((e as Error).message);
        console.error('Transaction Exception:\n\n', (e as Error).stack || (e as Error).message);
        transactionModel.update({
          description: errorMsg,
          status: TransactionStatus.fail,
        });

        if (!isRejectedFromUser(errorMsg)) {
          Sentry.captureMessage((e as Error).stack || (e as Error).message);
        }
        return false;
      }
    },
    [
      notifyRefresh,
      signAndExecuteTransaction,
      signTransaction,
      client,
      currentAccount,
      currentWallet,
    ],
  );

  return transact;
};

const isRejectedFromUser = (msg: string) => {
  return msg === 'Rejected from user' || msg.indexOf('user rejection') >= 0;
};

const transformMessage = (errorMessage: string) => {
  if (isRejectedFromUser(errorMessage)) return errorMessage;

  const matches = errorMessage.match(/name: Identifier\("(\w+)"\).*},\s*(\d+)\)/) as
    | [string, string]
    | null;
  if (!matches) return 'Transaction was not processed. Please try again.';

  const [swapRouter, value] = matches;
  const mappedMsg = transactionErrorMap[swapRouter]?.[value];
  if (mappedMsg) return mappedMsg;

  return `${
    swapRouter.toLowerCase().indexOf('swap') >= 0 ? 'Swap' : 'Transaction'
  } was not processed. Please try again.`;
};
