import { type FC, memo } from 'react';
import styles from './today-rewards.module.scss';
import starIcon from '@assets/images/today-rewards/star.svg';
import coin1Icon from '@assets/images/today-rewards/coin-1.svg';
import coin2Icon from '@assets/images/today-rewards/coin-2.svg';
import arrowForwardIcon from '@assets/images/icons/arrow-forward.svg';
import { usePools } from '@hooks/use-pools';
import { Decimal } from 'turbos-clmm-sdk';
import { useCoins } from '@hooks/use-coins';
import { getRewardWeekAmount } from '@utils/get-reward-week-amount';
import type { TurbosCoinItem } from '@models/coin.model';
import CoinLogo from '@ui/coin-logo';
import { Link } from 'react-router-dom';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import Loading from '@ui/loading';
import { turbosSdk } from '@libs/turbos-sdk';

const TodayRewards: FC = () => {
  const { pools, loading: poolsLoading } = usePools('today-reward', { category: 'farms' });
  const { coins, loading: coinsLoading } = useCoins();
  const loading = (poolsLoading && !pools.length) || (coinsLoading && !coins.length);

  const rewardSummary: Record<string, { coin: TurbosCoinItem; summary: Decimal }> = {};
  pools.forEach((pool) => {
    pool.reward_infos.forEach((reward) => {
      const coinType = turbosSdk.coin.formatCoinType(reward.fields.vault_coin_type);
      const coin = coins.find((item) => item.type === coinType);
      if (!coin) return;
      rewardSummary[coinType] ||= { coin, summary: new Decimal(0) };
      rewardSummary[coinType]!.summary = rewardSummary[coinType]!.summary.add(
        getRewardWeekAmount(reward).div(7),
      );
    });
  });

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Today Rewards:</p>
      <img src={starIcon} className={styles.star} />
      <img src={coin1Icon} className={`${styles.coin} ${styles.coin1}`} />
      <img src={coin2Icon} className={`${styles.coin} ${styles.coin2}`} />
      <div className={styles.body}>
        <div className={styles.content}>
          {loading && <Loading />}
          {Object.values(rewardSummary).map(({ coin, summary }) => {
            if (summary.isZero()) return null;
            return (
              <p key={coin.type}>
                <span>{bigNumberToReadable(summary, coin.decimals, true, 0)}</span>
                <CoinLogo src={coin.logo_url} />
              </p>
            );
          })}
        </div>
        <div className={styles.link}>
          <span>Earn now!</span>
          <Link to="/pools">
            <img src={arrowForwardIcon} data-icon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(TodayRewards);
