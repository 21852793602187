import { type FC, memo, useEffect } from 'react';
import styles from './index.module.scss';
import logo from '@assets/images/pictures/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { getHeaderMenuIcon, menus } from 'src/routers/menu';
import Social from './social';
import ConnectWallet from '@components/connect-wallet';
import mobileMenuIcon from '@assets/images/icons/mobile-menu.svg';
import carIcon from '@assets/images/prix/car.svg';
import Drawer from '@ui/drawer';
import { useToggle } from 'react-use';
import { ExplorerSetting } from './explorer-setting';
import Links from './links';
import SettingPreferredExplorer from './setting-preferred-explorer';
import SettingPreferredExplorerContent from './setting-preferred-explorer-content';
import SettingRpc from './setting-rpc';
import SettingRpcContent from './setting-rpc-content';
import Lottie from 'lottie-react';
import newFileJSON from '@assets/lottie/new-fire.json';

const Header: FC = () => {
  const location = useLocation();
  const [showDrawer, toggleDrawer] = useToggle(false);
  const [selecting, toggleSelecting] = useToggle(false);
  const [rpcSelecting, toggleRpcSelecting] = useToggle(false);

  useEffect(() => {
    toggleDrawer(false);
  }, [location.pathname]);

  const $menus = menus.map((menu) => {
    const external = menu.path.indexOf('http') === 0;
    const isActive = location.pathname.indexOf(menu.path) === 0;
    const $img = <img src={getHeaderMenuIcon(menu.key + (isActive ? '-active' : ''))} data-icon />;

    if (external || menu.target === '_blank') {
      return (
        <a
          href={menu.target ? '#' + menu.path : menu.path}
          key={menu.key}
          target={menu.target || '_self'}
        >
          {$img}
          <span>{menu.name}</span>
        </a>
      );
    }

    return (
      <Link to={menu.path} key={menu.key} className={isActive ? styles.active_menu : ''}>
        {$img}
        <span>{menu.name}</span>
        {menu.key === 'yieldStrategy' && (
          <Lottie animationData={newFileJSON} autoplay loop className={styles.lottie} />
        )}
      </Link>
    );
  });
  const prix = (
    <Link to="/prix" className={styles.gift}>
      <img src={carIcon} data-icon />
      <span>Turbos Grand Prix</span>
    </Link>
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img src={logo} alt="Turbos" className={styles.logo_img} />
        </div>
        <div className={styles.menus}>
          {$menus}
          {prix}
        </div>
        <div className={styles.right}>
          <Social />
          <ConnectWallet />
          <ExplorerSetting />
          <div data-slot data-slot-header-right className={styles.portal}></div>
          <div className={styles.mobile_menu}>
            <img src={mobileMenuIcon} onClick={toggleDrawer} />
          </div>
        </div>
      </div>
      <Drawer open={showDrawer} onClose={toggleDrawer} direction="left" className={styles.drawer}>
        <div className={styles.content}>
          <div>
            <img src={logo} alt="Turbos" className={styles.logo_img} />
            <div className={styles.mobile_menus}>
              {$menus}
              {prix}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.setting}>
              {!selecting && !rpcSelecting ? (
                <>
                  <SettingPreferredExplorer toggleSelecting={toggleSelecting} />
                  <div className={styles.driver}></div>
                  <SettingRpc toggleSelecting={toggleRpcSelecting} />
                </>
              ) : null}

              {selecting ? (
                <SettingPreferredExplorerContent toggleSelecting={toggleSelecting} />
              ) : null}
              {rpcSelecting ? <SettingRpcContent toggleSelecting={toggleRpcSelecting} /> : null}
            </div>

            <Links />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default memo(Header);
