import { config } from '@config';
import { findLocationSearch } from '@utils/location-search';
import { defineModel } from 'foca';
import { Network } from 'turbos-clmm-sdk';

const initialState: {
  coinTypeA: string;
  coinTypeB: string;
} = {
  coinTypeA: '0x2::sui::SUI',
  coinTypeB:
    config.sui_network === Network.mainnet
      ? '0x76cb819b01abed502bee8a702b4c2d547532c12f25001c9dea795a5e631c26f1::fud::FUD'
      : '',
};

export const swapCoinModel = defineModel('swap-coin', {
  initialState,
  reducers: {
    updateA(state, coinType: string) {
      state.coinTypeA = coinType;
    },
    updateB(state, coinType: string) {
      state.coinTypeB = coinType;
    },
    exchange(state) {
      return {
        coinTypeA: state.coinTypeB,
        coinTypeB: state.coinTypeA,
      };
    },
  },
  events: {
    onInit() {
      const hash = window.location.hash.replace(/.*\?/, '');
      const search = window.location.search.replace(/^\?/, '');
      if (search) {
        const input = findLocationSearch(search, 'input');
        const output = findLocationSearch(search, 'output');
        if (input) {
          this.updateA(input);
        }
        if (output) {
          this.updateB(output);
        }
      } else if (hash) {
        const input = findLocationSearch(hash, 'input');
        const output = findLocationSearch(hash, 'output');
        if (input) {
          this.updateA(input);
        }
        if (output) {
          this.updateB(output);
        }
      }
    },
  },
});
