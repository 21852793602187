import type { PaginatedCoins, DryRunTransactionBlockResponse, SuiClient } from '@mysten/sui/client';
import { normalizeSuiObjectId } from '@mysten/sui/utils';
import { unstable_getObjectId } from 'turbos-clmm-sdk';
import { Transaction, type TransactionObjectArgument } from '@mysten/sui/transactions';
import { Decimal, Network } from 'turbos-clmm-sdk';
import http from './http';
import { turbosSdk } from './turbos-sdk';
import { isSuiCoinAddress } from '@utils/is-sui-coin-address';

export interface TEvents
  extends Array<
    Omit<DryRunTransactionBlockResponse['events'][number], 'parsedJson'> & {
      parsedJson: DeepbookParsedJson;
    }
  > {}

type CoinData = PaginatedCoins['data'];

export interface DeepbookRouter {
  isBid: boolean;
  deepbook: boolean;
  txb: Transaction;
  deepbookPrice: string;
  deepbookFee: string;
  amountIn: string;
  amountOut: string;
}

export interface DeepbookParsedJson {
  base_asset_quantity_filled: string;
  base_asset_quantity_remaining: string;
  is_bid: boolean;
  maker_address: string;
  maker_client_order_id: string;
  maker_rebates: string;
  order_id: string;
  original_quantity: string;
  pool_id: string;
  price: string;
  taker_address: string;
  taker_client_order_id: string;
  taker_commission: string;
}

interface DeepBookConfigDataType {
  pools: {
    clob: string;
    type: string;
  }[];
  tokens: {
    type: string;
    symbol: string;
    decimals: number;
  }[];
}

export class DeepbookSdk {
  readonly client_order_id: number = 89;
  readonly fee: number = 0.25 / 100;
  readonly packageId = '0xdee9';
  deepbookConfig!: DeepBookConfigDataType;
  readonly decimalsCache: { [x: string]: number } = {
    '0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::usdt::USDT': 7,
  };

  constructor(
    readonly network: Network,
    readonly provider: SuiClient,
    readonly gasBudget: number = 1_000_000,
  ) {
    this.init();
  }

  async init() {
    const version = '1.0.0';
    const deepbookVersion = localStorage.getItem('DeepbookVersion');
    const deepbookConfigs = await http.get<Record<Network, DeepBookConfigDataType>>(
      'https://s3.amazonaws.com/app.turbos.finance/sdk/deepbook.json',
      {
        headers: {
          'Cache-control': deepbookVersion === version ? 'public' : 'no-cache',
        },
      },
    );
    localStorage.setItem('DeepbookVersion', version);

    this.deepbookConfig = deepbookConfigs[this.network];
  }

  public async swap_exact_quote_for_base(
    token1: string,
    token2: string,
    poolId: string,
    amountIn: number,
    currentAddress: string,
  ) {
    const txb = new Transaction();

    // get token objectId
    const coinsData = await this.getCoinsData(currentAddress, token2, amountIn);
    const [sendCoin, mergeCoin] = this.splitAndMergeCoin(coinsData, amountIn, txb);
    if (!sendCoin) {
      throw new Error(`Not enough balance: ${token2}`);
    }

    // get accountCap id
    const currentAccountCap = await this.IsAccountCap(currentAddress);
    const accountCap = !currentAccountCap ? this.createAccount(txb) : txb.object(currentAccountCap);

    const [base_coin_ret, quote_coin_ret, _amount] = txb.moveCall({
      typeArguments: [token1, token2],
      target: `${this.packageId}::clob_v2::swap_exact_quote_for_base`,
      arguments: [
        txb.object(String(poolId)),
        txb.pure.u64(this.client_order_id),
        accountCap,
        txb.pure.u64(amountIn),
        txb.object(normalizeSuiObjectId('0x6')),
        sendCoin,
      ],
    });

    if (mergeCoin) {
      txb.transferObjects([mergeCoin], txb.pure.address(currentAddress));
    }

    txb.transferObjects([base_coin_ret!], txb.pure.address(currentAddress));
    txb.transferObjects([quote_coin_ret!], txb.pure.address(currentAddress));

    if (!currentAccountCap) {
      txb.transferObjects([accountCap], txb.pure.address(currentAddress));
    }

    txb.setSenderIfNotSet(currentAddress);
    txb.setGasBudget(this.gasBudget);

    return txb;
  }

  public async swap_exact_base_for_quote(
    token1: string,
    token2: string,
    poolId: string,
    amountIn: number,
    currentAddress: string,
  ): Promise<Transaction> {
    const txb = new Transaction();

    // get token objectId
    const coinsData = await this.getCoinsData(currentAddress, token1, amountIn);
    const [sendCoin, mergeCoin] = this.splitAndMergeCoin(coinsData, amountIn, txb);

    if (!sendCoin) {
      throw new Error(`Not enough balance: ${token1}`);
    }

    // get accountCap id
    const currentAccountCap = await this.IsAccountCap(currentAddress);
    const accountCap = !currentAccountCap ? this.createAccount(txb) : txb.object(currentAccountCap);

    const zero = this.zero(token2, txb);

    const [base_coin_ret, quote_coin_ret, _amount] = txb.moveCall({
      typeArguments: [token1, token2],
      target: `${this.packageId}::clob_v2::swap_exact_base_for_quote`,
      arguments: [
        txb.object(String(poolId)),
        txb.pure.u64(this.client_order_id),
        accountCap,
        txb.pure.u64(amountIn),
        sendCoin,
        zero,
        txb.object(normalizeSuiObjectId('0x6')),
      ],
    });
    if (mergeCoin) {
      txb.transferObjects([mergeCoin], txb.pure.address(currentAddress));
    }

    txb.transferObjects([base_coin_ret!], txb.pure.address(currentAddress));
    txb.transferObjects([quote_coin_ret!], txb.pure.address(currentAddress));

    if (!currentAccountCap) {
      txb.transferObjects([accountCap], txb.pure.address(currentAddress));
    }

    txb.setSenderIfNotSet(currentAddress);
    txb.setGasBudget(this.gasBudget);
    return txb;
  }
  async dry_run_transaction_block(
    txb: Transaction,
    currentAddress: string,
  ): Promise<DryRunTransactionBlockResponse> {
    const trans = Transaction.from(txb);
    trans.setSenderIfNotSet(currentAddress);
    trans.setGasBudget(this.gasBudget);
    const build = await trans.build({ client: this.provider, onlyTransactionKind: false });
    const dryRunResult = await this.provider.dryRunTransactionBlock({
      transactionBlock: build,
    });
    if (dryRunResult.effects.status.status !== 'success') {
      throw new Error(dryRunResult.effects.status.error);
    }
    return dryRunResult;
  }

  async dev_inspect_swap_exact_quote_for_base(
    token1: string,
    token2: string,
    poolId: string,
    amountIn: number,
    currentAddress: string,
  ): Promise<DeepbookRouter> {
    const txb = await this.swap_exact_quote_for_base(
      token1,
      token2,
      poolId,
      amountIn,
      currentAddress,
    );
    const transaction = await this.dry_run_transaction_block(txb, currentAddress);
    const [token1Decimals, token2Decimals] = await Promise.all([
      this.findDecimals(token1),
      this.findDecimals(token2),
    ]);

    const events = transaction.events as TEvents;
    const parsedJson = events[0]!.parsedJson;
    const balanceChanges = transaction.balanceChanges;
    const prices = events.map((event) => event.parsedJson.price);

    const price = this.getDeepbookPrice(prices, token2Decimals);
    const fee = new Decimal(amountIn)
      .div(10 ** token2Decimals)
      .mul(this.fee)
      .toString();

    const amount = balanceChanges.find((coin) => coin.coinType === token1)?.amount || '0';
    const amountOut =
      Number(amount) > 0
        ? new Decimal(amount)
            .sub(new Decimal(fee).div(price))
            .div(10 ** token1Decimals)
            .toString()
        : '0';
    return {
      isBid: parsedJson.is_bid,
      deepbook: true,
      txb: txb,
      deepbookPrice: price,
      deepbookFee: fee,
      amountIn: new Decimal(amountIn).div(10 ** token2Decimals).toString(),
      amountOut,
    };
  }

  async dev_inspect_swap_exact_base_for_quote(
    token1: string,
    token2: string,
    poolId: string,
    amountIn: number,
    currentAddress: string,
  ): Promise<DeepbookRouter> {
    const txb = await this.swap_exact_base_for_quote(
      token1,
      token2,
      poolId,
      amountIn,
      currentAddress,
    );

    const transaction = await this.dry_run_transaction_block(txb, currentAddress);
    const [token1Decimals, token2Decimals] = await Promise.all([
      this.findDecimals(token1),
      this.findDecimals(token2),
    ]);

    const events = transaction.events as TEvents;
    const parsedJson = events[0]!.parsedJson;
    const balanceChanges = transaction.balanceChanges;
    const prices = events.map((event) => event.parsedJson.price);

    const price = this.getDeepbookPrice(prices, token2Decimals);
    const fee = new Decimal(amountIn)
      .div(10 ** token1Decimals)
      .mul(this.fee)
      .mul(price)
      .toString();

    const amount = balanceChanges.find((coin) => coin.coinType === token2)?.amount || '0';
    const amountOut =
      Number(amount) > 0
        ? new Decimal(amount)
            .sub(new Decimal(fee).div(price))
            .div(10 ** token2Decimals)
            .toString()
        : '0';
    return {
      isBid: parsedJson.is_bid,
      deepbook: true,
      txb: txb,
      deepbookPrice: price,
      deepbookFee: fee,
      amountIn: new Decimal(amountIn).div(10 ** token1Decimals).toString(),
      amountOut,
    };
  }

  splitAndMergeCoin(
    coins: CoinData | undefined,
    amount: number,
    txb: Transaction,
  ): [TransactionObjectArgument | undefined, TransactionObjectArgument | undefined] {
    if (!coins || coins.length < 1) {
      return [undefined, undefined];
    }

    if (isSuiCoinAddress(coins[0]!.coinType)) {
      const [sendCoin] = txb.splitCoins(txb.gas, [txb.pure.u64(amount)]);
      return [sendCoin, undefined];
    }

    const mergeCoin = txb.object(coins[0]!.coinObjectId);
    if (coins.length > 1) {
      txb.mergeCoins(
        mergeCoin,
        coins.slice(1).map((coin) => txb.object(coin.coinObjectId)),
      );
    }

    const [sendCoin] = txb.splitCoins(mergeCoin, [txb.pure.u64(amount)]);
    return [sendCoin, mergeCoin];
  }

  async getCoinsData(currentAddress: string, type: string, amount: number): Promise<CoinData> {
    const coinObjects: CoinData = [];
    let coinFields: PaginatedCoins | undefined;
    do {
      coinFields = await this.provider.getCoins({
        owner: currentAddress,
        coinType: type,
        cursor: coinFields?.nextCursor,
      });
      coinObjects.push(...coinFields.data);
    } while (coinFields.hasNextPage);

    const resultCoinObjects: CoinData = [];
    let currentBalance = 0;
    coinObjects
      .sort((coinA, coinB) => Number(coinB.balance) - Number(coinA.balance))
      .some((object) => {
        if (currentBalance >= amount) {
          return true;
        } else {
          currentBalance += Number(object.balance);
          resultCoinObjects.push(object);
          return false;
        }
      });
    return resultCoinObjects;
  }

  async IsAccountCap(currentAddress: string): Promise<string | undefined> {
    const dynamicFields = await this.provider.getOwnedObjects({
      owner: currentAddress,
      options: { showContent: true, showType: true, showOwner: true },
      filter: {
        StructType: `${this.packageId}::custodian_v2::AccountCap`,
      },
    });
    return dynamicFields.data[0]?.data
      ? unstable_getObjectId(dynamicFields.data[0].data)
      : undefined;
  }

  createAccount(txb: Transaction): TransactionObjectArgument {
    let [cap] = txb.moveCall({
      typeArguments: [],
      target: `${this.packageId}::clob_v2::create_account`,
      arguments: [],
    });
    return cap!;
  }

  protected zero(token: string, txb: Transaction): TransactionObjectArgument {
    return txb.moveCall({
      typeArguments: [token],
      target: `0x2::coin::zero`,
      arguments: [],
    });
  }

  getDeepbookPrice(prices: string[], decimals: number): string {
    return prices
      .reduce((sum, price) => sum.add(price), new Decimal(0))
      .div(prices.length)
      .div(10 ** decimals)
      .toString();
  }

  findDecimals(type: string): number {
    const token = this.deepbookConfig.tokens.find((token) => token.type === type);
    return token!.decimals;
  }

  findPool(coinA: string, coinB: string): string | undefined {
    const pool = this.deepbookConfig.pools.find((pool) => {
      const types = turbosSdk.pool.parsePoolType(pool.type);
      return types[0] === coinA && types[1] === coinB;
    });
    return pool ? pool.clob : undefined;
  }

  getPoolTypeAAndTypeB(): string[][] {
    return this.deepbookConfig.pools.map((pool) => {
      return turbosSdk.pool.parsePoolType(pool.type);
    });
  }
}
