import Button from '@ui/button';
import Dialog from '@ui/dialog';
import { TransactionStatus } from '@constants/transaction-status';
import { type FC, memo } from 'react';
import logoIcon from '@assets/images/pictures/logo.png';
import styles from './index.module.scss';
import { useModel } from 'foca';
import { transactionModel } from '@models/transaction.model';
import { globalSettingModel } from '@models/global-setting.model';
import Lottie from 'lottie-react';
import CarOopsJSON from '@assets/lottie/Car-Ooops.json';
import CarSucceededJSON from '@assets/lottie/Car-Succeeded.json';
import ConfirmingJSON from '@assets/lottie/Confirming.json';

const Transaction: FC = () => {
  const { status, digest, visible, description, title, successTip, errorTip, finishedTime } =
    useModel(transactionModel);
  const closable = status === TransactionStatus.success || status === TransactionStatus.fail;

  return (
    <Dialog
      visible={visible}
      centered
      width={500}
      title={<img src={logoIcon} className={styles.title_img} />}
      closable={closable}
      maskClosable={closable}
      onClose={transactionModel.toggleVisible}
      className={styles.dialog}
      rootClassName={styles.dialog_root}
    >
      <div className={styles.inner}>
        {status === TransactionStatus.confirm ? (
          <>
            <Lottie animationData={ConfirmingJSON} autoplay loop className={styles.lottie} />
            <p>Confirming</p>
          </>
        ) : status === TransactionStatus.success ? (
          successTip ? (
            successTip
          ) : (
            <>
              <Lottie animationData={CarSucceededJSON} autoplay loop className={styles.lottie} />
              <p>{title || 'Succeeded!'}</p>
            </>
          )
        ) : status === TransactionStatus.fail ? (
          errorTip ? (
            errorTip
          ) : (
            <>
              <Lottie animationData={CarOopsJSON} autoplay loop className={styles.lottie} />
              <p>Oops!</p>
            </>
          )
        ) : null}
        <div className={styles.desc}>{description}</div>
        {finishedTime ? (
          <div className={styles.time}> Finalized in {finishedTime} seconds</div>
        ) : null}
        {closable && (
          <div className={styles.close_button}>
            <Button
              type="normal"
              hoverHighlight
              onClick={transactionModel.toggleVisible}
              fullWidth
              size="large"
            >
              Close
            </Button>
          </div>
        )}
        {status === TransactionStatus.success && !!digest ? (
          <a href={globalSettingModel.explorerUrlForTransaction(digest)} target="_blank">
            View on Explorer ↗
          </a>
        ) : null}
      </div>
    </Dialog>
  );
};

export default memo(Transaction);
