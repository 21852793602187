import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
// import { swapCoinModel } from '@models/swap-coin.model';
// import { useNavigate } from 'react-router-dom';

export const Top: FC = () => {
  // const navigator = useNavigate();
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.deepbook}`}
            onClick={() => {
              window.open('https://testnet.turbos.finance/deepbookv3/#/trade', '_blank');
            }}
          ></div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.blub}`}
            onClick={() => {
              swapCoinModel.updateB(
                '0xfa7ac3951fdca92c5200d468d31a365eb03b2be9936fde615e69f0c1274ad3a0::BLUB::BLUB',
              );
              swapCoinModel.updateA('0x2::sui::SUI');
              navigator(
                '/trade?input=0x2::sui::SUI&output=0xfa7ac3951fdca92c5200d468d31a365eb03b2be9936fde615e69f0c1274ad3a0::BLUB::BLUB',
              );
            }}
          ></div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};
