import { type FC, memo, useEffect } from 'react';
import styles from './swap-header.module.scss';
import { useRefreshIcon } from '@hooks/use-refresh';
import { useToggle } from 'react-use';
import onIcon from '@assets/images/deepbook/on.svg';
import offIcon from '@assets/images/deepbook/off.svg';
import Tooltip from '@ui/tooltip';

interface SwapHeaderProps {
  onDeepBookActive(active: boolean): void;
}
const SwapHeader: FC<SwapHeaderProps> = (props) => {
  const { onDeepBookActive } = props;
  const RefreshView = useRefreshIcon();
  const [on, toggleOn] = useToggle(false);

  useEffect(() => {
    onDeepBookActive(on);
  }, [on]);

  return (
    <div className={styles.header}>
      <p className={styles.title}>Trade</p>

      <div className={styles.deepbook}>
        <Tooltip onlyMessage overlay="Trade on DeepBook" placement="top">
          <div
            className={`${styles.deepbook_on_off} ${on ? styles.deepbook_on_off_active : ''}`}
            onClick={toggleOn}
          >
            <div className={`${styles.deepbook_on} ${!on ? styles.deepbook_hide : ''}`}>ON</div>
            <div className={`${styles.deepbook_off} ${on ? styles.deepbook_hide : ''}`}>OFF</div>
            <div className={`${styles.active} ${on ? styles.active_on : styles.active_off}`}>
              <img src={on ? onIcon : offIcon} data-con />
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={styles.refresh_view}>{RefreshView}</div>
    </div>
  );
};

export default memo(SwapHeader);
