import { type FC, memo, useEffect } from 'react';
import styles from './wallet-connected.module.scss';
import Dropdown from '@ui/dropdown';
import arrowIcon from '@assets/images/icons/arrow-down.svg';
import { useToggle } from 'react-use';

import { useCurrentAccount, useCurrentWallet, useDisconnectWallet } from '@mysten/dapp-kit';
import WalletList from './wallet-list';
import { useAddressDomain } from '@hooks/use-address-domain';
import { formatAddress } from '@utils/format-address';

const WalletConnected: FC = () => {
  const currentAccount = useCurrentAccount();
  const wallet = useCurrentWallet();
  const [domain] = useAddressDomain(currentAccount?.address || '');
  const [showMenu, toggleShowMenu] = useToggle(false);
  const { mutate: disconnect } = useDisconnectWallet();

  useEffect(() => {
    // Disconnect from wallet extension
    !currentAccount && disconnect();
  }, [!!currentAccount]);

  return (
    <Dropdown
      onVisibleChange={toggleShowMenu}
      placement="bottomRight"
      overlay={<WalletList />}
      onOverlayClick={toggleShowMenu}
    >
      <div className={`${styles.button} ${showMenu ? styles.active : ''}`} key="connected">
        <img src={wallet.currentWallet?.icon} className={styles.wallet_icon} />
        <span>
          {formatAddress({
            domain,
            label: currentAccount?.label,
            address: currentAccount?.address,
          })}
        </span>
        <img src={arrowIcon} className={styles.arrow_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(WalletConnected);
