import { Network } from 'turbos-clmm-sdk';
import production, { type Config } from './mainnet';

const development: Config = {
  ...production,
  sui_network: Network.devnet,
  api_url: 'http://34.235.165.224:6002',
  // api_url: 'https://api.turbos.finance/testnet/',
  explorer_url: {
    suiscan: {
      txb: 'https://suiscan.xyz/testnet/tx/{{txb}}',
      account: 'https://suiscan.xyz/testnet/account/{{account}}',
      object: 'https://suiscan.xyz/testnet/object/{{object}}',
      coin: 'https://suiscan.xyz/testnet/coin/{{coin}}',
    },
    suivision: {
      txb: 'https://testnet.suivision.xyz/txblock/{{txb}}',
      account: 'https://testnet.suivision.xyz/account/{{account}}',
      object: 'https://testnet.suivision.xyz/object/{{object}}',
      coin: 'https://testnet.suivision.xyz/coin/{{coin}}',
    },
    sui_explorer: {
      txb: 'https://suiexplorer.com/txblock/{{txb}}?network=testnet',
      account: 'https://suiexplorer.com/address/{{account}}?network=testnet',
      object: 'https://suiexplorer.com/object/{{object}}?network=testnet',
      coin: 'https://suiexplorer.com/object/{{coin}}?network=testnet',
    },
  },
  rpc: {
    suiscan: 'https://rpc-mainnet.suiscan.xyz/',
    sui_Official: 'https://fullnode.testnet.sui.io:443',
    suiet: ' https://mainnet.suiet.app/',
    shinami: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  },
  fullnode: 'https://fullnode.testnet.sui.io:443',
};

export default development;
