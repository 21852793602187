import { parseGlobIcon } from '@utils/parse-glob-icon';

export const menus: {
  path: string;
  name: string;
  key: string;
  target?: string;
}[] = [
  {
    path: '/trade',
    name: 'Trade',
    key: 'trade',
  },
  {
    path: '/pools',
    name: 'Earn',
    key: 'pool',
  },
  {
    path: '/yield-strategy',
    name: 'Yield Strategy',
    key: 'yieldStrategy',
  },
  // {
  //   path: 'https://app.turbos.finance/turbostar',
  //   name: 'TurboStar',
  //   key: 'turbostar',
  // },
  {
    path: '/bridge',
    name: 'Bridge',
    key: 'bridge',
    target: '_blank',
  },
  {
    path: '/stats',
    name: 'Stats',
    key: 'stats',
  },
];

// if (import.meta.env.MODE !== 'mainnet') {
//   menus.push({
//     path: '/faucet',
//     key: 'faucet',
//     name: 'Faucet',
//   });
// }

export const getHeaderMenuIcon = parseGlobIcon(
  import.meta.glob('../assets/images/menus/*', {
    eager: true,
    import: 'default',
  }),
);
